<template>
  <div class="warp">
    <div class="detail-info">
      <div class="detail">
        <div class="title">{{ detailInfo.netName }}</div>
        <div class="last-time">最后补货时间 :<br />{{ detailInfo.repairTime }}</div>
      </div>
      <div class="infos-warp">
        <div class="infos">
          <span class="span">机器数量：{{ vmcCount }}</span>
          <span>当前缺货数：{{ outStocks }}</span>
        </div>
        <div class="address">网点位置：{{ detailInfo.address }}</div>
      </div>
    </div>
    <div class="empty-div"></div>
    <div v-for="(item, index) in detailInfo.statisticsInfos" :key="index" class="item">
      <div @click="showInfos(index)">
        <div class="title">{{ item.vmcName }}</div>
        <div class="address"><span style="width: 240px">{{ item.address }}</span><span class="out-stocks">缺货：{{ item.outStocks }}</span></div>
        <van-icon name="arrow-up" class="arrow" v-if="item.showInfo"/>
        <van-icon name="arrow-down" class="arrow" v-if="!item.showInfo"/>
      </div>
      <div class="info-count" v-if="item.showInfo">
        <div class="info-div" @click="toDetail(item.vmcId, 'quehuoxinxi')">
          <van-icon :name="queImg" size="20"/><br/>缺货
        </div>
        <div class="info-div" @click="toDetail(item.vmcId, 'stockList')">
          <van-icon :name="curCtImg" size="20"/><br/>库存
        </div>
        <div class="info-div" @click="toDetail(item.vmcId, 'retentionList')">
          <van-icon :name="Img3" size="20"/><br/>格口滞留
        </div>
        <div class="info-div" @click="toDetail(item.vmcId, 'outOfStockList')">
          <van-icon :name="Img4" size="20"/><br/>断货
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vmcInfoStatistics } from '../../assets/api/netPoint.js'
export default {
  data() {
    return {
      netId: this.$route.query.netId,
      vmcCount: this.$route.query.vmcCount,
      netName: this.$route.query.netName,
      outStocks: this.$route.query.outStocks,
      address: this.$route.query.address,
      queImg: require('../../assets/imgs/que.png'),
      curCtImg: require('../../assets/imgs/curct.png'),
      Img3: require('../../assets/imgs/sale-detail.png'),
      Img4: require('../../assets/imgs/vem-remain.png'),
      detailInfo: {}
    }
  },
  mounted() {
    this.getVmcinfo()
  },
  methods: {
    getVmcinfo() {
      vmcInfoStatistics({netId: this.netId}).then(res => {
        if (res.data.code == 200) {
          this.detailInfo = res.data.data
        }
      })
    },
    showInfos(index) {
      this.detailInfo.statisticsInfos[index].showInfo = !this.detailInfo.statisticsInfos[index].showInfo
    },
    toDetail(vmcId, sign) {
      this.$router.push({
        name: 'net_point_detail_infos',
        query: {
          vmcId: vmcId,
          sign: sign
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.warp {
  .detail-info {
    padding: 15px 10px;
    .detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .title {
        font-size: 16px;
      }
      .last-time {
        color: #666;
        text-align: right;
      }
    }
    .infos-warp {
      font-size: 14px;
      color: #666;
      .infos {
        .span {
          margin-right: 20px;
        }
      }
    }
  }
  .empty-div {
    height: 20px;
    background: #efefef;
  }
  .item {
    margin: 15px 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdee0;
    position: relative;


    .title {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
    }
    .address {
      display: flex;
      .out-stocks {
        color: red;
        margin-top: -10px;
      }
    }
    .infos {
      height: 24px;
      line-height: 24px;
      .span {
        margin-right: 10px;
        color: #666;
      }
    }
    .arrow {
      position: absolute;
      right: 0;
      top: 15px;
      font-size: 16px;
      color: #999
    }
    .info-count {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 5px 0;
      .info-div {
        width: 25%;
        text-align: center;
      }
    }
  }
}
</style>
